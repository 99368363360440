<template>
  <div class="content">
    <div class="box">
      <div class="box-item">
        <div class="box-lf">
          <span>门店图片</span>
        </div>
        <div class="box-rg">
          <div class="upload-box" @click="uploadDialogVisible = true">
            <el-image
              style="width: 80px; height: 80px"
              :src="shopInfoData.computerImageUrl"
            ></el-image>
            <div class="tips"><span>修改图片</span></div>
          </div>
          <!-- <el-upload
            :action="`${$config.baseUrl}/api/common/fileInfo/newUpload`"
            :show-file-list="false"
            name="files"
            :before-upload="beforeAvatarUpload"
            :on-success="handleAvatarSuccess"
          >
           
          </el-upload> -->
        </div>
      </div>
      <div class="box-item">
        <div class="box-lf">
          <span>店铺名称</span>
        </div>
        <div class="box-rg">
          <span>{{ shopInfoData.companyName }}</span>
        </div>
      </div>
      <div class="box-item">
        <div class="box-lf">
          <span>店铺ID</span>
        </div>
        <div class="box-rg">
          <span>{{ shopInfoData.ownShopId }}</span>
        </div>
      </div>
      <div class="box-item">
        <div class="box-lf">
          <span>联系电话</span>
        </div>
        <div class="box-rg">
          <el-input
            placeholder="445464654"
            :value="shopInfoData.phone"
            size="small"
            style="width:240px"
            disabled
          ></el-input>
          <div class="eidt">
            <span @click="eidtPhone"> 更改</span>
          </div>
        </div>
      </div>
      <div class="box-item">
        <div class="box-lf">
          <span>详细地址</span>
        </div>
        <div class="box-rg">
          <div class="box-rg">
            <span
              >{{ shopInfoData.province }} {{ shopInfoData.city }}
              {{ shopInfoData.country }} {{ shopInfoData.otherAddress }}</span
            >
            <div class="eidt" @click="eidtAddress"><span> 更改</span></div>
          </div>
        </div>
      </div>
      <div class="box-item">
        <div class="box-lf">
          <span>法人姓名</span>
        </div>
        <div class="box-rg">
          <span>{{ shopInfoData.realName }}</span>
        </div>
      </div>
      <div class="box-item">
        <div class="box-lf">
          <span>法人证件</span>
        </div>
        <div class="box-rg">
          <span>{{ shopInfoData.idCardNumber }}</span>
        </div>
      </div>
      <div class="box-item">
        <div class="box-lf">
          <span>营业执照</span>
        </div>
        <div class="box-rg">
          <div class="upload-box">
            <el-image
              style="width: 80px; height: 80px"
              :src="shopInfoData.businessImageUrl"
            ></el-image>
          </div>
        </div>
      </div>
      <div class="box-item">
        <div class="box-lf">
          <span>身份证正面</span>
        </div>
        <div class="box-rg">
          <div class="upload-box">
            <el-image
              style="width: 80px; height: 80px"
              :src="shopInfoData.idCardFrontImageUrl"
            ></el-image>
          </div>
        </div>
      </div>
      <div class="box-item">
        <div class="box-lf">
          <span>身份证反面</span>
        </div>
        <div class="box-rg">
          <div class="upload-box">
            <el-image
              style="width: 80px; height: 80px"
              :src="shopInfoData.idCardBackImageUrl"
            ></el-image>
          </div>
        </div>
      </div>
      <div class="box-item">
        <div class="box-lf">
          <span>手持身份证</span>
        </div>
        <div class="box-rg">
          <div class="upload-box">
            <el-image
              style="width: 80px; height: 80px"
              :src="shopInfoData.idCardHandImageUrl"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- 地址更改 -->
      <el-dialog
        title="更改地址"
        :visible.sync="addressDialogVisible"
        width="50%"
        :before-close="addressClose"
      >
        <div>
          <el-row :gutter="20">
            <el-col :span="2">
              <span>详细地址</span>
            </el-col>
            <el-col :span="4">
              <el-select
                v-model="provinceId"
                placeholder="省"
                @change="provinceChange"
              >
                <el-option
                  v-for="item in addressData"
                  :key="item.provinceId"
                  :label="item.province"
                  :value="item.provinceId"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4">
              <el-select v-model="cityId" placeholder="市" @change="cityChange">
                <el-option
                  v-for="item in cityList"
                  :key="item.cityId"
                  :label="item.city"
                  :value="item.cityId"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select v-model="areaId" @change="areaChange" placeholder="区">
                <el-option
                  v-for="item in areaList"
                  :key="item.areaId"
                  :label="item.area"
                  :value="item.areaId"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="streetAddress"
                placeholder="请输入详细地址"
              ></el-input>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addressClose">取 消</el-button>
          <el-button type="primary" @click="addressOnSubmit">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 手机号更改 -->
      <el-dialog
        title="更改手机号"
        :visible.sync="auditDialogVisible"
        width="30%"
        @close="auditDialogClose"
      >
        <div>
          <el-form ref="auditForm" :model="auditForm" :rules="rules">
            <el-form-item label="联系电话" label-width="80px" prop="phone">
              <el-input
                v-model.number="auditForm.phone"
                maxlength="11"
                oninput="value=value.replace(/\D/g,'')"
                style="width:280px"
                suffix-icon="el-icon-mobile-phone"
                placeholder="请输入手机号"
              />
            </el-form-item>
            <el-form-item label="验证码" prop="code" label-width="80px">
              <div style=" display: flex;align-items: center;">
                <el-input
                  style="margin-right:20px;width:280px"
                  type="text"
                  maxlength="6"
                  placeholder="验证码"
                  v-model="auditForm.code"
                />
                <el-button
                  class="btn-orange"
                  :disabled="disabled"
                  @click="getCode"
                  >{{ valiBtn }}</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="auditDialogClose">取 消</el-button>
          <el-button type="primary" @click="auditOnSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 废弃 -->
      <!-- <el-dialog
        title="验证新手机号"
        :visible.sync="eidtPhoneDialogVisible"
        width="30%"
      >
        <div>
          <el-form ref="eidtPhoneForm" :model="eidtPhoneForm" :rules="rules">
            <el-form-item label="新手机号" prop="phone" label-width="80px">
              <el-input
                type="text"
                maxlength="11"
                suffix-icon="el-icon-mobile-phone"
                style="width:280px"
                placeholder="手机号"
                v-model="eidtPhoneForm.phone"
              />
            </el-form-item>
            <el-form-item label="验证码" prop="code" label-width="80px">
              <div style=" display: flex;align-items: center;">
                <el-input
                  style="margin-right:20px;width:280px"
                  type="text"
                  maxlength="6"
                  placeholder="验证码"
                  v-model="eidtPhoneForm.code"
                />
                <el-button
                  class="btn-orange"
                  :disabled="eidtDisabled"
                  @click="eidtGetCode"
                  >{{ eidtValiBtn }}</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="eidtPhoneDialogClose">取 消</el-button>
          <el-button type="primary" @click="eidtPhoneOnSubmit">确 定</el-button>
        </span>
      </el-dialog> -->

      <el-dialog
        title="图片上传"
        :visible.sync="uploadDialogVisible"
        width="30%"
      >
        <el-upload
          class="upload-demo"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          name="files"
          multiple
          accept=".png"
          :on-success="handleAvatarSuccess"
        >
          <div v-if="!computerImageUrl">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </div>
          <div v-else>
            <el-image :src="computerImageUrl"></el-image>
          </div>
        </el-upload>
        <span slot="footer" class="dialog-footer">
          <el-button @click="uploadDialogClose">取 消</el-button>
          <el-button type="primary" @click="uploadDialogSubmit">
            确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      // const reg = /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/;
      // const reg = /^1[35789]\d{9}$/;
      const reg = /^1[/d]{10}$/;
      if (reg.test(value)) {
        return callback();
      }
      callback("手机号必须11位");
    };
    return {
      uploadDialogVisible: false,
      uploadData: {
        id: null,
        userId: null,
      },
      eidtPhoneDialogVisible: false, //修改手机号弹窗显示控制
      eidtValiBtn: "获取验证码", //修改获取验证码
      eidtDisabled: false, //修改验证码按钮
      // 修改手机号验证
      eidtPhoneForm: {
        phone: "",
        code: "",
      },
      uploadUrl: process.env.VUE_APP_API + "/api/common/fileInfo/newUpload",
      provinceData: null, // 当前选择省份数据
      addressData: [], // 省市区数据
      provinceId: null, //省ID
      province: null,
      cityList: [], //市列表
      cityId: null, //市ID
      city: null,
      areaId: null, //区ID
      area: null,
      areaList: [], //区列表
      streetAddress: "", //详细地址
      auditDialogVisible: false, //手机号验证
      shopInfoData: [], // 商铺数据
      disabled: false,
      computerImageUrl: "",
      // 身份验证
      auditForm: {
        phone: "",
        code: "",
      },
      rules: {
        phone: [
          // { validator: checkPhone, required: true, trigger: "blur" },

          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      valiBtn: "获取验证码",
      addressDialogVisible: false,
    };
  },
  created() {
    this.getInfo();
    this.uploadData.userId = this.$store.state.user.userId;
  },
  methods: {
    // 省切换
    provinceChange(value) {
      const data = this.addressData.find((item) => item.provinceId === value);
      this.cityList = data.cityMap;
      // 当前选中省数据
      this.provinceId = data.provinceId;
      this.province = data.province;

      this.cityId = null;
      this.areaId = null;
    },
    // 市切换
    cityChange(value) {
      const data = this.cityList.find((item) => item.cityId === value);

      this.areaList = data.areaMap;
      this.cityId = data.cityId;
      this.city = data.city;

      this.areaId = null;
    },
    // 区切换
    areaChange(value) {
      const data = this.areaList.find((item) => item.areaId === value);
      this.areaId = data.areaId;
      this.area = data.area;
    },
    // 获取省市区
    getAddress() {
      let addressData = JSON.parse(localStorage.getItem("addressData"));
      if (addressData) {
        // 上一次请求该接口时间戳
        let oldDate = localStorage.getItem("lastGetAddressDate");
        // 当前时间戳
        let nowDate = Date.parse(new Date());
        let minusDate = nowDate - oldDate;
        if (minusDate < 86400000) {
          this.addressData = addressData;
          console.log(addressData[0]);
          return;
        }
      }
      this.$api.view.getAddress().then((res) => {
        const data = res.data;
        if (res.success) {
          localStorage.setItem("addressData", JSON.stringify(data.areaData));
          // 当前请求接口成功省市区
          localStorage.setItem("lastGetAddressDate", Date.parse(new Date()));
        }
        // 数据村本地
        this.addressData = data.areaData;
      });
    },
    // 修改地址弹窗显示
    eidtAddress() {
      this.getAddress();

      this.addressDialogVisible = true;
    },
    // 修改地址提交
    addressOnSubmit() {
      if (
        !this.cityId ||
        !this.provinceId ||
        !this.areaId ||
        !this.streetAddress
      ) {
        this.$message.error("数据请填写完整");
        return;
      }
      let resData = {
        id: this.uploadData.id,
        userId: this.uploadData.userId,
        cityCode: this.cityId,
        city: this.city,
        countryCode: this.areaId,
        country: this.area,
        otherAddress: this.streetAddress,
        province: this.province,
        provinceCode: this.provinceId,
      };

      this.$api.user.updateShopPhoneAndAddress(resData).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: "修改地址成功",
          });
          // 数据局部更新
          this.$set(this.shopInfoData, "province", this.province);
          this.$set(this.shopInfoData, "city", this.city);
          this.$set(this.shopInfoData, "country", this.area);
          this.$set(this.shopInfoData, "otherAddress", this.streetAddress);
          this.addressDialogVisible = false;
        } else {
          this.$message({
            type: "error",
            message: "修改地址失败",
          });
        }
      });
    },
    // 修改地址关闭
    addressClose() {
      // 地址内容清空
      this.cityId = null;
      this.streetAddress = null;
      this.areaId = null;
      this.provinceId = null;
      this.addressDialogVisible = false;
    },
    eidtPhone() {
      this.auditDialogVisible = true;
    },

    // 获取验证码
    getCode() {
      // this.auditForm.phone = this.shopInfoData.phone;
      this.$refs["auditForm"].validateField("phone", (err) => {
        if (err) {
          return;
        } else {
          this.tackBtn(); //验证码倒数60秒
          let resData = {
            mobile: this.auditForm.phone,
            sign: this.$md5(
              this.auditForm.phone + ":" + this.$store.state.messageKey
            ),
          };
          this.$api.user.sendVerificationCode(resData).then((res) => {
            this.$message.success(res.message);
          });
        }
      });
    },

    uploadDialogClose() {
      //图片上传弹窗
      this.computerImageUrl = "";
      this.uploadDialogVisible = false;
    },

    // 图片上传提交
    uploadDialogSubmit() {
      let resData = {
        id: this.uploadData.id,
        userId: this.uploadData.userId,
        computerImageUrl: this.computerImageUrl,
      };
      this.$api.user.updateShopPhoneAndAddress(resData).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: res.message,
          });
          // 数据局部更新
          this.$set(
            this.shopInfoData,
            "computerImageUrl",
            this.computerImageUrl
          );
          this.uploadDialogClose();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },

    // 修改获取验证码
    eidtGetCode() {
      this.$refs["eidtPhoneForm"].validateField("phone", (err) => {
        if (err) {
          return;
        } else {
          this.eidtTackBtn(); //验证码倒数60秒
          let resData = {
            mobile: this.eidtPhoneForm.phone,
            sign: this.$md5(
              this.eidtPhoneForm.phone + ":" + this.$store.state.messageKey
            ),
          };
          this.$api.user.sendVerificationCode(resData).then((res) => {
            this.$message.success(res.message);
          });
        }
      });
    },

    eidtTackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.eidtValiBtn = "获取验证码";
          this.eidtDisabled = false;
        } else {
          this.eidtDisabled = true;
          this.eidtValiBtn = time + "秒后重试";
          time--;
        }
      }, 1000);
    },

    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重试";
          time--;
        }
      }, 1000);
    },

    // 验证身份dialog关闭
    auditDialogClose() {
      this.$refs["auditForm"].resetFields();
      this.getInfo();
      this.auditDialogVisible = false;
    },

    /* 修改手机号 */
    eidtPhoneOnSubmit() {
      let resData = {
        phone: this.eidtPhoneForm.phone,
        password: this.eidtPhoneForm.code,
      };
      this.$api.user.VerificationCodeVerify(resData).then((res) => {
        //验证码是否正确
        const { data, success, message } = res;
        if (!success) {
          this.$message.error(message);
        } else {
          // this.$message.success(message);
          // this.eidtPhoneDialogVisible = true;
          let resultData = {
            id: this.uploadData.id,
            userId: this.uploadData.userId,
            phone: this.eidtPhoneForm.phone,
          };
          // 修改代码
          this.$api.user
            .updateShopPhoneAndAddress(resultData)
            .then((result) => {
              if (result.success) {
                this.$message.success(result.message);
                this.$set(this.shopInfoData, "phone", this.eidtPhoneForm.phone);
                this.eidtPhoneDialogClose();
              } else {
                this.$message.error(result.message);
              }
            });
        }
      });
    },

    // 修改手机dialog关闭
    eidtPhoneDialogClose() {
      this.$refs["eidtPhoneForm"].resetFields();
      this.eidtPhoneDialogVisible = false;
    },

    // 验证身份dialog提交 ---new 直接更新联系电话
    auditOnSubmit() {
      let resData = {
        phone: this.auditForm.phone,
        verifyCode: this.auditForm.code,
        id: this.shopInfoData.id,
        userId: this.shopInfoData.user_id,
      };
      this.$api.user.VerificationCodeVerify(resData).then((res) => {
        //验证码是否正确
        const { data, success, message } = res;
        if (!success) {
          this.$message.error(message);
        } else {
          // this.eidtPhoneDialogVisible = true;
          this.$message.success(message);
          this.auditDialogVisible = false;
          this.auditDialogClose();
        }
      });
    },

    // 获取商铺信息
    getInfo() {
      this.$api.user.shopInfo().then((res) => {
        const data = res.data;
        this.uploadData.id = data.id;
        this.shopInfoData = data;
      });
    },

    // 照片上传成功
    handleAvatarSuccess(res, file) {
      if (res.success) {
        this.$message({
          message: res.message,
          type: "success",
        });
      }
      this.computerImageUrl = res.data[0].fileUrl;
      // this.$set(this.shopInfoData, "computerImageUrl", res.data[0].fileUrl);
    },
  },
};
</script>

<style scoped lang="scss">
.upload-demo {
  display: flex;
  justify-content: center;
  margin: 16px 0;
  align-items: center;
}
.content {
  .box {
    font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
    font-weight: 400;
    color: #606266;
    font-size: 14px;
    margin-top: 24px;
    .box-item {
      margin: 16px 0;
      display: flex;
      align-items: center;
      .box-lf {
        width: 80px;
      }
      .box-rg {
        color: #999999;
        .eidt {
          display: inline-block;
          color: $color-theme;
          margin-left: 8px;
          cursor: pointer;
        }
        .upload-box {
          cursor: pointer;
          width: 80px;
          height: 80px;
          border-radius: 4px;
          overflow: hidden;
          position: relative;
          .tips {
            position: absolute;
            bottom: 0;
            width: 80px;
            line-height: 24px;
            text-align: center;
            background-color: $color-theme;
            color: #fff;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
